.discounts-container {
    margin: 0 1rem 3rem;
    flex-wrap: wrap;
    display: flex;
	 justify-content: space-between;
}
.banner {
	margin: 1rem 1rem 0 0;
}

@media (max-width: 767px) {
	.discounts-container {
		margin: 0 1rem 3rem;
		flex-wrap: wrap;
		display: flex;
		justify-content: space-around;
  }
  .toolpic-text {
	  position: absolute;
	  left: 10px;
  }
	.banner {
		margin-top: 1rem;
		width: 250px;
	}
 }