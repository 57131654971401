.min-adv-title {
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 121.07%;
	color: #414141;
}

.min-adv-desc {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	color: #414141;
}

.quality-img {
	width: 570px;
}

.banner-about{
    border-radius: 10px;
    background-size: 100% 100%;
    height: 525px;
    background-repeat: no-repeat;
    border-radius: 10px;
    display: flex;
    flex-direction: row ;
}
.about-banner-left{
    border-radius: 10px;
    height: 100%;
    width: 50%;
    background: rgba(0, 0, 0, 0.6);
}
.left-q-desc {
	text-align: left;
	width: 100%;
}
.about-banner-title{
    font-size: 45px;
    color: #EA1C24;
    font-weight: 700;
}
.about-banner-left-wrap{
        height: 100%;
    padding: 60px 40px;
}
.about-line{
    width: 74px;
    height: 3px;
    background: #FFFFFF;
}
.about-left-desc{
    font-size: 32px;
    color: white;
}
.about-mini-banner{
    bottom: -2.8rem;
    left: -4rem;
    width: 520px;
    height: 200px;
    position: absolute;
    background-image: url(../../assets/images/about/about-banner-bg2.png);
}
.about-banner-right{
    position: relative;
    width: 50%;
    height: 100%;
}
.about-right-desc{
    padding: 40px 40px 0 40px;
    margin: 0;
    font-size: 16px;
    color: white    ;
}
.about-section{
    margin-top: 100px;
}
.quality-wrap{
    display: flex;
}
.quality-title{
    font-size: 32px;
    font-weight: 700;
}
.quality-item:not(:first-child){
    margin-top: 65px;
}
.quality-image-about {
    bottom: -100px;
	 width: 100%;
    position: absolute;
    left: 230px;
}
.q-ab{
    height: 470px;
}
.left-q-about{
    background-size: 100% 100%;
}
.description-row{
    display: flex;
    margin-top: 5rem;
    flex-wrap: wrap;
    justify-content: space-between;
}
.description-row img{
    width: 360px;
}
.description-row-item{
    width: 450px;
}
.desc-row-title{
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	line-height: 27px;
	color: #414141;
}
.desc-row-desc {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: #414141;
}

/* For Media (MOBILE) */

/* Ipad(Pro) */
@media(max-width:1050px){
	/* Nothing (DON'T TUCH!!!) */
	.quality-image-about {
		width: 75%;
		left: 285px;
	}
	.company_title {
		margin: -100px 0 0 0;
	}
	.about-mini-banner {
		margin-left: 20px;
	}
 }

 @media(max-width:912px) { 
	.quality-image-about {
		width: 75%;
		left: 285px;
		bottom: 0;
		top: 350px;
	}
	.description-row-wrap{
		display: flex;
		flex-direction: column;
		align-items: center;
  }
  .quality-text{
		margin: 0 !important;
		text-align: center;
  }
  .right-quality{
		padding: 0;
  }
  .banner-about{
		background-size: 170% 100%;
  }
  .quality-image-about{
		display: none;
  }
  .description-row{
		justify-content: center;
  }
  .q-ab{
		height: 100% !important;
  }
  .about-banner-left{
		padding: 0 1rem;
  }
  .about-left-desc{
		font-size: 15px;
  }
  .about-section-item img {
		width: 200px;
  }
  .quality-wrap {
	  justify-content: center;
	  width: 80%;
  }
  .quality-img {
	  width: 320px;
  }
  .quality {
	  justify-content: center;
	  margin-top: 200px;
	  margin-bottom: 125px;
  }
  .quality-item {
	  margin-left: 140px;
  }
  }
 
 @media(max-width:820px){
	.quality-desc {
		width: 720px;
	}
	.description-row-wrap{
		display: flex;
		flex-direction: column;
		align-items: center;
  }
  .quality-text{
		margin: 0;
		text-align: left;
  }
  .right-quality{
		padding: 0;
  }
  .banner-about{
		background-size: 170% 100%;
  }
  .quality-image-about{
		display: none;
  }
  .description-row{
		justify-content: center;
  }
  .q-ab{
		height: 100% !important;
  }
  .about-banner-left{
		padding: 0 1rem;
  }
  .about-left-desc{
		font-size: 15px;
  }
  .about-section-item img {
		width: 200px;
  }
  .quality-img {
	  width: 320px;
  }
  .quality {
	  margin: 0 0 -80px 0;
  }
 }
 
 /* Surface Duo() */
 @media (max-width: 540px) {
	.quality {
		margin: 0 0 -80px 0;
	}
	.quality-item {
		margin: 0 0 0 85px;
	}
	.quality-desc {
		width: 525px;
	}
 } 
 
 /* iphone (6/7/8/Plus) */
  @media (max-width: 414px) {
	.quality {
		margin: 0 0 -80px 0;
	}
	.quality-item {
		margin: 0 0 0 60px;
	}
	.min-adv-desc {
		font-size: 14px;
	}
	.quality-desc {
		width: 395px;
	}
 } 
 
 @media (max-width: 395px) {
	.quality-desc {
		width: 380px;
	}
 } 
 
 /* iphone (6/7/8) */
  @media (max-width: 375px) {
	.quality-desc {
		width: 350px;
	}
 } 
 
 /* iphone (5/SE) */
  @media (max-width: 320px) {
	 .description-row img {
		 width: 100%;
	 }
	 .quality-item {
		 width: 310px;
		margin: 0 0 0 30px;
	}
	.quality-desc {
		width: 300px;
	}
 }