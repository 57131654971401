.form {
	position: relative;
}

.form p {
	position: relative;
}

.login-register-confirm-password {
	width: 25px;
	height: 25px;
	position: absolute;
	top: 258px;
	left: -15px;
	z-index: 1;
}

.login-register-password {
	width: 25px;
	height: 25px;
	position: absolute;
	top: 183px;
	left: -15px;
	z-index: 1;
}

.login-register-email {
	width: 22px;
	height: 22px;
	position: absolute;
	top: 108px;
	left: -14px;
	z-index: 1;
}

.login-register-input {
	width: 25px;
	height: 25px;
	position: absolute;
	top: 31px;
	left: -15px;
	z-index: 1;
}

.login-password {
	width: 25px;
	height: 25px;
	position: absolute;
	top: 108px;
	left: -15px;
	z-index: 1;
}

.login-input {
	width: 22px;
	height: 22px;
	position: absolute;
	top: 33px;
	left: -15px;
	z-index: 1;
}

.login-registered {
	font-size: 18px;
	text-decoration: none;
}

.login-registered:hover {
	color: red;
}

.login-reset:hover {
	color: red;
}

.login-reset {
	font-size: 18px;
	text-decoration: none;
}

._eye_orig {
	right: 0;
	top: 10px;
	width: 30px;
	position: absolute;
}

.register-titles {
	padding-top: 20px;
	display: flex;
	justify-content: space-around;
}

.register-form {
	background: #F4F3F8;
	margin: 0 auto;
	width: 500px;
	height: 500px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
}

.register-inner {
	padding: 3rem;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.form p input {
	font-size: 18px;
	height: 55px;
	background: #FFF;
	border: 1px solid #dfd9d9a8;
	outline: none;
	border-radius: 10px;
	width: 100%;
	padding-left: 40px;
	margin-left: -25px;
}

.form {
	width: 100%;
}

@media(max-width:992px) {
	.form p input {
		font-size: 16px;
		height: 55px;
		background: #FFF;
		border: 1px solid #dfd9d9a8;
		outline: none;
		border-radius: 10px;
		width: 86%;
		padding-left: 40px;
		margin-left: -5px;
	}

	.login-register-confirm-password {
		width: 25px;
		height: 25px;
		position: absolute;
		top: 258px;
		left: 0;
		z-index: 1;
	}

	.login-register-password {
		width: 25px;
		height: 25px;
		position: absolute;
		top: 183px;
		left: 0;
		z-index: 1;
	}

	.login-register-email {
		width: 22px;
		height: 22px;
		position: absolute;
		top: 108px;
		left: 0;
		z-index: 1;
	}

	.login-register-input {
		width: 25px;
		height: 25px;
		position: absolute;
		top: 31px;
		left: 0;
		z-index: 1;
	}

	.login-password {
		width: 25px;
		height: 25px;
		position: absolute;
		top: 108px;
		left: 0;
		z-index: 1;
	}

	.login-input {
		width: 22px;
		height: 22px;
		position: absolute;
		top: 33px;
		left: 0;
		z-index: 1;
	}

	.register-form {
		width: 300px;
	}
}