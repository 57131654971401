.profile-info {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
}

.profile-info-inner {
	margin-top: 15px;
	width: 60%;
}

.profile-info-inner-items-name {
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.profile-info-inner-items-flex {
	display: flex;
	margin-left: 3px;
	align-items: center;
}

.profile-info-inner-items-flex img {
	width: 20px;
	height: 20px;
	margin-right: 7px;
}

.profile-info-inner-items input {
	background: #efefef;
	height: 55px;
	padding: 0 0 0 5px;
	border: 1px solid #d1cccca8;
	outline: none;
	border-radius: 10px;
	width: 100%;
	margin: 5px 0 15px 0;
}

.send-ava {
	margin: 0 auto;
	margin-top: 1rem;
	width: 100px;
	height: 40px;
	background: #EA1C24;
	border-radius: 10px;
	color: white;
	outline: none;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.cabinet_profile {
	width: 100%;
}

.avatar {
	display: flex;
	flex-direction: column;
}

.cabinet_menu ul {
	margin-right: 0 !important;
}

.cabinet_menu {
	/*align-items: center;*/
}

.cabinet_menu ul {
	margin-right: 0.625rem !important;
}

@media (max-width: 320px) {
	.file-upload {
		top: 55px;
		right: 0;
		margin-bottom: 10px;
		margin-right: 50px;
	}

	.cabinet_menu {
		align-items: center;
	}
}

@media (max-width: 820px) {
	.cabinet_menu {
		align-items: center;
	}

	.file-upload {
		top: 55px;
		right: 60px;
		margin-bottom: 10px;
		margin-right: 50px;
	}
}

@media (max-width: 540px) {
	.file-upload {
		margin-bottom: 10px;
		margin-right: 50px;
		top: 55px;
		right: 0;
	}

	.cabinet_menu {
		align-items: center;
	}
}

@media (max-width: 768px) {
	.file-upload {
		top: 55px;
		right: 40px;
		margin-bottom: 10px;
		margin-right: 50px;
	}

	.cabinet_menu {
		align-items: center;
	}
}

@media (max-width: 414px) {
	.file-upload {
		top: 55px;
		right: 5px;
		margin-bottom: 10px;
		margin-right: 50px;
	}

	.cabinet_menu {
		align-items: center;
	}
}

@media (max-width: 395px) {
	.file-upload {
		margin-bottom: 10px;
		margin-right: 50px;
	}

	.cabinet_menu {
		align-items: center;
	}
}

@media (max-width: 375px) {
	.file-upload {
		margin-bottom: 10px;
		margin-right: 50px;
	}

	.cabinet_menu {
		align-items: center;
	}
}