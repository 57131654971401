.sasha {
	display: none;
}

.catalog-filter {
	width: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.in-catalog {
	display: flex;
	background: #ED1C24;
	padding: 0.5rem 0 0.5rem 1.5rem;
	border-radius: 6px 6px 0px 0px;
	color: white;
	align-items: center;
	justify-content: space-between;
}

.elems:last-child {
	display: none;
}

.arrow-down-catalog {
	margin-top: 0.5rem;
}

.catalog-inner {
	margin-top: 1rem;
	display: flex;
	flex-direction: row;
}

.items-inner {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(13.5rem, 1fr));
	width: 100%;
	position: relative;
}

.items {
	margin-left: 1.5rem;
	width: 100%;
}

.card {
	margin-bottom: 1rem;
}

.title-filters {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
}

.catalog-filters {
	margin-right: 25px;
	height: 40px;
	display: flex;
	flex-direction: row;
	background: #F4F3F8;
	border-radius: 4px;
}

.catalog-filters>div {
	font-size: 14px;
	font-weight: 400;
}

.filter-title-cont {
	display: flex;
	align-items: center;
}

.filter-active {
	box-shadow: 0px 1px 2px #F4F3F8, 0px 2px 4px #F4F3F8;
	border-radius: 4px;
	color: red;
}

.all-filter,
.discounts-filter,
.available-filter {
	cursor: pointer;
	width: 85px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.all-filter,
.discounts-filter,
.available-filter div {
	font-size: 14px;
}

.filter-dropdown-cont {
	width: 46.4%;
	display: flex;
	justify-content: space-between;
}

.filter-dropdown-btn img {
	margin-left: 0.5rem;
}

.filter-dropdown-btn {
	display: flex;
	width: 210px;
	cursor: pointer;
	padding: 1rem;
	border: 1px solid #D2CEE0;
	box-sizing: border-box;
	border-radius: 4px;
	align-items: center;
	justify-content: center;
}

.dropdown-filters {
	margin-left: 10px;
	z-index: 2;
	position: absolute;
	background-color: #fff;
	-webkit-box-shadow: 0px 0px 8px 4px rgba(34, 60, 80, 0.25);
	-moz-box-shadow: 0px 0px 8px 4px rgba(34, 60, 80, 0.25);
	box-shadow: 0px 0px 8px 4px rgba(34, 60, 80, 0.25);
	border-radius: 4px;
	padding: 1rem;
}

.dropdown-filters li {
	cursor: pointer;
	padding: 0.5rem;
	list-style: none;
	font-size: 18px;
}

.dropdown-filters li:hover {
	transition: 0.2s;
	background-color: rgb(255, 0, 0);
	color: white;
}

.dropdown-filters li:not(:last-child) {
	border-bottom: 1px solid rgba(0, 0, 0, 0.212);
}

.catalog {
	background-color: #F4F3F8;
	height: 680px;
}

/* For Media (MOBILE) */

/* Ipad(Pro) */
@media(max-width:1050px) {

	/* DONT TUCH!!! */
	.items-inner {
		display: flex;
		width: 550px;
		margin: 0 auto;
		justify-content: space-evenly;
		flex-wrap: wrap;
	}

	.catalog_pagination_inner {
		margin-right: 140px;
	}

	.catalog {
		height: 100%;
	}
}

@media(max-width:820px) {
	.catalog-title {
		-webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
		-moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
		box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
	}

	.catalog {
		height: 100%;
	}

	.catalog-incatalog {
		justify-content: space-between;
	}

	.catalog-conatiner-first {
		display: flex;
		align-items: center;
	}

	.arrow-down-catalog {
		margin-top: 0.5rem;
		transition: 0.2s;
	}

	.catalog-incatalog {
		justify-content: space-between;
		align-items: center;
	}

	.dropdown-filters {
		margin-left: 0;
	}

	.filter-dropdown-cont {
		align-items: center;
		width: 100%;
		margin-bottom: 1.5rem;
	}

	.catalog-filters {
		width: 265px;
		margin: 0 auto;
	}

	.title-filters {
		flex-direction: column;
	}

	.filter-dropdown-cont {
		flex-direction: column;
	}

	.sidebar {
		width: 100%;
	}

	.catalog-filter {
		width: 100%;
	}

	.catalog-inner {
		flex-direction: column;
	}

	.items {
		margin: 0 auto;
		width: 700px;
	}

	.items-inner {
		display: flex;
		width: 700px;
		margin: 1rem auto auto 0;
		justify-content: center;
		flex-wrap: wrap;
	}

	.pagination-left {
		margin: 0 -20px 0 0;
	}

	.pagination-right {
		margin: 0 0 0 -25px;
	}

	.catalog_pagination_inner {
		margin-right: 200px;
	}
}

/* Surface Duo() */
@media (max-width: 540px) {
	.catalog_pagination_inner {
		margin-right: 70px;
	}

	.catalog {
		height: 100%;
	}

	.items {
		margin: 0 auto;
		width: 535px;
	}

	.items-inner {
		padding: 0 0 0 18px;
		margin: 10px 0 0 0;
		width: 505px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.card_main_block {
		width: 200px;
		margin: 0 auto;
	}
}

/* iphone (6/7/8/Plus) */
@media (max-width: 414px) {
	.catalog {
		height: 100%;
	}

	.card_main_block {
		width: 150px;
		margin: 0 auto;
	}

	.items-inner {
		width: 405px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		margin: 10px 0 0 -40px;
	}

	.items {
		margin: 0 auto;
		width: 405px;
	}

	.pagination-left {
		margin: 0 -20px 0 0;
	}

	.pagination-right {
		margin: 0 0 0 -25px;
	}

	.catalog_pagination_inner {
		margin-right: 74px;
	}
}

@media (max-width: 395px) {
	.catalog {
		height: 100%;
	}

	.items {
		margin: 0 auto 0 auto;
		width: 380px;
		display: flex;
	}

	.items-inner {
		margin: 1rem 22px 0 0;
		width: 370px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
	}

	.pagination-left {
		margin: 0 -40px 0 0;
	}

	.pagination-right {
		margin: 0 0 0 -50px;
	}

	.catalog_pagination_inner {
		width: 303px;
		margin: 0;
	}

	.catalog_pagination {
		width: 303px;
		margin: 0 0 0 15px;
	}

	.pagination {
		margin: 0 0 0 0;
	}

	.page-item {
		margin: 0 -5px;
	}
}

/* iphone (6/7/8) */
@media (max-width: 375px) {
	.catalog {
		height: 100%;
	}

	.items {
		margin: 0 auto;
		width: 330px;
	}

	.items-inner {
		width: 350px;
		margin: 10px -15px 0 0;
		display: flex;
		justify-content: space-between;
	}

	.pagination {
		margin: 0 0 0 5px;
	}

	.page-item {
		margin: 0 -5px;
	}
}

/* iphone (5/SE) */
@media (max-width: 320px) {
	.catalog {
		height: 100%;
	}

	.card_main_block {
		width: 130px;
		margin: 0 auto;
	}

	.items {
		margin: 0 auto 0 -5px;
		width: 315px;
	}

	.items-inner {
		width: 315px;
		margin: 10px 20px 0 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.pagination {
		margin: 0 0 0 5px;
	}

	.page-item {
		margin: 0 -5px;
	}

	.catalog_pagination_inner {
		width: 300px;
		margin-left: -75px;
	}
}

@media (max-width: 280px) {
	.items {
		width: 278px;
		margin: 0 auto;
	}

	.items-inner {
		width: 278px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
	}

	.catalog_pagination {
		width: 270px;
		margin: 0 0 0 -60px;
	}

	.pagination {
		margin: 0 0 0 0;
	}

	.page-item {
		margin: 0 -8px;
	}
}