.returns-page-container {
	max-width: 1100px;
	width: 100%;
	margin: 0 auto;
}

.returns-name-email {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.returns-name {
	max-width: 450px;
	width: 100%;
}

.returns-email {
	max-width: 450px;
	width: 100%;
}

.returns-name-input {
	border: none;
	background-color: rgba(189, 181, 181, 0.267);
	width: 100%;
	padding: 1rem 0 1rem 0.5rem;
	border-radius: inherit;
}

.returns-email-input {
	border: none;
	background-color: rgba(189, 181, 181, 0.267);
	width: 100%;
	padding: 1rem 0 1rem 0.5rem;
	border-radius: inherit;
}

.returns-comment {
	border: none;
	padding: 1rem 0 1rem 0.5rem;
	background-color: rgba(189, 181, 181, 0.267);
	width: 100%;
	border-radius: inherit;
	margin-bottom: 10px;
}


/* For Media (MOBILE) */

/* Ipad(Pro) */
@media(max-width:1050px){
}
 
 @media(max-width:820px){
	.returns-page-container {
		max-width: 1100px;
		width: 100%;
		margin: 0 auto;
	}
	
	.returns-name-email {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
	}
	
	.returns-name {
		max-width: 350px;
		width: 100%;
	}
	
	.returns-email {
		max-width: 350px;
		width: 100%;
	}
	
	.returns-name-input {
		border: none;
		background-color: rgba(189, 181, 181, 0.267);
		width: 100%;
		padding: 1rem 0 1rem 5px;
		border-radius: inherit;
	}
	
	.returns-email-input {
		margin: 0 0 0 -6px;
		border: none;
		background-color: rgba(189, 181, 181, 0.267);
		width: 100%;
		padding: 1rem 0 1rem 5px;
		border-radius: inherit;
	}
	
	.returns-comment {
		border: none;
		margin-left: -5px;
		padding: 1rem 0 1rem 5px;
		background-color: rgba(189, 181, 181, 0.267);
		width: 100%;
		border-radius: inherit;
		margin-bottom: 10px;
	}
}
 
 /* Surface Duo() */
 @media (max-width: 540px) { 
	.returns-name {
		max-width: 250px;
		width: 100%;
	}
	
	.returns-email {
		max-width: 250px;
		width: 100%;
	}
	
	.returns-name-input {
		border: none;
		background-color: rgba(189, 181, 181, 0.267);
		width: 100%;
		padding: 1rem 0 1rem 5px;
		border-radius: inherit;
	}
	
	.returns-email-input {
		margin: 0 0 0 -6px;
		border: none;
		background-color: rgba(189, 181, 181, 0.267);
		width: 100%;
		padding: 1rem 0 1rem 5px;
		border-radius: inherit;
	}
} 
 
 /* iphone (6/7/8/Plus) */
  @media (max-width: 414px) {
	.returns-name {
		max-width: 190px;
		width: 100%;
	}
	
	.returns-email {
		max-width: 190px;
		width: 100%;
	}
}
 
 @media (max-width: 395px) {
	.returns-name {
		max-width: 182px;
		width: 100%;
	}
	
	.returns-email {
		max-width: 182px;
		width: 100%;
	}
} 
 
 /* iphone (6/7/8) */
  @media (max-width: 375px) {
	.returns-name {
		max-width: 165px;
		width: 100%;
	}
	
	.returns-email {
		max-width: 165px;
		width: 100%;
	}
} 
 
 /* iphone (5/SE) */
  @media (max-width: 280px) {
}