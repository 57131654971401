
    .dropdown_current{
        border: 1px solid #D5D5D5;
        border-radius: 100px;
        padding: 0.5rem 2rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        color: gray;
    }
    .dropdown_list{
        position: absolute;
        /* top: 100%; */
        z-index: 4;
        border: 1px solid #D5D5D5;
        border-top: none;
        overflow: hidden;
        max-height: "0";
        background-color: white;
        width: 66%;
        transition: 0.2s all;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    .dropdown_list div{
              transition: 0.3s all;
            text-align: left;
            color: gray;
            cursor: pointer;
            text-transform: capitalize;
            padding: 0.5rem 2rem;
    }
    .dropdown_list div:hover{
        background-color: #FAF6F6;
    }