.hamburger-menu{
    display: none;
}
#menu__toggle {
  opacity: 0;
}

.menu__box{
    z-index: 1;
}
#menu__toggle:checked ~ .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked ~ .menu__btn > span::before {
  top: 0;
  transform: rotate(0);
}
#menu__toggle:checked ~ .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
  width: 100%;
  visibility: visible;
  right: 0;
}
.mobile-logo{
  margin: 2rem auto;
}
.menu__btn {
  display: flex;
  align-items: center;
  position: fixed;
  top: 40px;
  right: 20px;

  width: 26px;
  height: 26px;

  cursor: pointer;
  z-index: 2;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;

  width: 100%;
  height: 2px;

  background-color: #616161;

  transition-duration: .25s;
}
.menu__btn > span::before {
  content: '';
  top: -8px;
}
.menu__btn > span::after {
  content: '';
  top: 8px;
}

.menu__box {
  display: block;
  position: fixed;
  visibility: hidden;
  top: 0;
  right: -100%;

  width: 300px;
  height: 100%;

  margin: 0;
  padding: 160px 0;

  list-style: none;

  background-color: #ECEFF1;
  box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);

  transition-duration: .25s;
  z-index: 3;
}

.menu__item {
  display: block;
  padding: 12px 24px;

  color: #333;

  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;

  text-decoration: none;

  transition-duration: .25s;
}
.menu__item:hover {
  background-color: #CFD8DC;
}
@media(max-width:1050px){
    .hamburger-menu{
        display: block;
    }
	 .menu__btn {
		display: flex;
		align-items: center;
		position: fixed;
		top: 63px;
		right: 10px;
	 
		width: 26px;
		height: 26px;
	 
		cursor: pointer;
		z-index: 4;
	 }
	 .menu__box {
		 padding: 90px 0;
	 }
}

@media(max-width:820px){
	.hamburger-menu{
		 display: block;
	}
	.menu__box {
		padding: 160px 0;
	}
	.menu__btn {
	  display: flex;
	  align-items: center;
	  position: fixed;
	  top: 94px;
	  right: 20px;
	
	  width: 26px;
	  height: 26px;
	
	  cursor: pointer;
	  z-index: 4;
	}
}

@media(max-width:414px){
	.hamburger-menu{
		 display: block;
	}
	.menu__box {
		padding: 160px 0;
	}
	.menu__btn {
		display: flex;
		align-items: center;
		position: fixed;
		top: 94px;
		right: 20px;
	 
		width: 26px;
		height: 26px;
	 
		cursor: pointer;
		z-index: 4;
	 }
}

@media(max-width:395px){
	.hamburger-menu{
		 display: block;
	}
	.menu__box {
		padding: 160px 0;
	}
	.menu__btn {
		display: flex;
		align-items: center;
		position: fixed;
		top: 94px;
		right: 20px;
	 
		width: 26px;
		height: 26px;
	 
		cursor: pointer;
		z-index: 4;
	 }
}

@media(max-width:375px){
	.hamburger-menu{
		 display: block;
	}
	.menu__box {
		padding: 160px 0;
	}
	.menu__btn {
		display: flex;
		align-items: center;
		position: fixed;
		top: 94px;
		right: 20px;
	 
		width: 26px;
		height: 26px;
	 
		cursor: pointer;
		z-index: 4;
	 }
}