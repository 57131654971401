.payment-type{
    width: 31%;
	 margin: 0 13px;
}
.payment-title{
    background: #F4F3F8;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.payment-title-inner{
    font-size: 22px;
    font-weight: 400;
}
.payment-types-inner{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.delivery-map-title{
    display: flex;
	 flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}
.delivery-zones-title{
    display: flex;
    flex-direction: row;
    padding: 20px 80px
}
.delivery-zones-title p{
    width: 25%;
}
.delivery-zones-title p:first-child{
    width: 50%;
}
.map{
    width: 100%;
}
iframe{
    width: 100%;
}
.delivery-zones{
    margin-bottom: 120px;
}
.delivery-zone {
	align-items: center;
}
@media(max-width: 650px){
    .delivery-zones{
        flex-direction: row;
        align-items: center;
    }
	 .delivery-zones-title {
		 width: 33%;
	 }
     .delivery-zones-title p{
        margin: 0 20px;
    }
	 /*
    .delivery-zones-title p:first-child{
        width: 35%;
    } */
}
@media(max-width:767px){
    .payment-types-inner{
        display: flex;
        flex-direction: column;
    }
	 .payment-type{
		width: 31%;
		margin: 0px;
  }
}

/* For Media (MOBILE) */
 
 /* Surface Duo() */
 @media (max-width: 540px) {
	.delivery-zones{
		flex-direction: row;
  }
	.delivery-zones-title {
		margin: 0 0 0 -20px;
		width: 67%;
		justify-content: center;
	}
	.delevery_first_title {
		font-size: 15px;
		line-height: 20px;
	}
 } 
 
 /* iphone (6/7/8/Plus) */
  @media (max-width: 414px) {
	.delivery-zones{
		flex-direction: row;
  }
	.delivery-zones-title {
		margin: 0 0 0 -20px;
		width: 66%;
		justify-content: center;
	}
	.delevery_first_title {
		font-size: 13px;
		line-height: 20px;
	}
	.delivery-zone-title {
		font-size: 13px;
	}
	.delivery-zone-over {
		font-size: 13px;
	}
} 
 
 @media (max-width: 395px) {
	.delivery-zones{
		flex-direction: row;
  }
	.delivery-zones-title {
		margin: 0 0 0 0;
		width: 100%;
		justify-content: center;
	}
	.delevery_first_title {
		font-size: 13px;
		line-height: 20px;
	}
	.delivery-zone-title {
		font-size: 13px;
	}
	.delivery-zone-over {
		font-size: 13px;
	}
 } 
 
 /* iphone (6/7/8) */
  @media (max-width: 375px) {

 } 
 
 /* iphone (5/SE) */
  @media (max-width: 320px) {
	.container {
		padding: 0 5px;
	} 
 } 
 