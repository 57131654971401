.delivery-zone{
    background: #FFFFFF;
    border: 1px solid #F4F3F8;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
}
.delivery-zone-title{
    width: 46%;
}
.select-cont{
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: center
}
.select{
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 2px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 3px;
}
.delivery-zone *:not(:nth-child(2)):not(:first-child){
    width: 23%;
}
.active-select{
    width: 100%;
    height: 100%;
    background: #ED1C24;
    border-radius: 3px;
}
@media(max-width:767px){
    .delivery-zone{
        justify-content: space-between;
    }
    .delivery-map-title div h1{
        text-align: center;
        font-size: 24px !important;
    }
}