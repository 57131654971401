.delete_main_icon {
	width: 10px;
	height: 10px;
	margin: 10px 0 0 205px;
	padding: 2px;
	cursor: pointer;
}

.cart-item-img {
	object-fit: contain;
}

.modell {
	margin-left: 10px;
}

.btns {
	align-items: center;
	margin: 0 5px 0 0;
}

.item-pic-sick {
	width: 150px;
	object-fit: contain;
	justify-content: center;
}

.item-pic-container {
	margin-left: 22px;
	width: 200px;
	display: flex;
	justify-content: center;
}

.prices {
	margin: 0
}

._comparase_product_title {
	width: 90%;
	height: 120px;
	padding: 0 5px 0 5px;

}

/* .style={{ color: "#414141", fontWeight: '700', fontStyle: 'normal', fontSize: '16px' }} */
.card-price {
	color: #414141;
	font-weight: 700;
	font-style: normal;
	font-size: 16px;
	margin: 0 0 0 5px;
}

/* for null blocks */
._comparase_charac_null {
	width: 242px;
	margin-bottom: 0;
}

._comparase_charac_null_first_block {
	width: 100%;
	margin: 0;
}

._comparase_charac_null_articule {
	width: 100%;
	font-size: 18px;
	color: gray;
	padding: 0 3px;
	background-color: rgba(196, 189, 189, 0.438);
	min-height: 68px;
}

._comparase_charac_null_value {
	width: 100%;
	height: 80px;
	color: gray;
	font-size: 18px;
	padding: 0 3px;
	background-color: rgba(233, 225, 225, 0.527);
}

.compare_right {
	border-radius: 50%;
	cursor: pointer;
	width: 30px;
	height: 34px;
	margin: -2px 3px 0 0;
}

.compare_right img {
	border-radius: 50%;
	width: 100%;
	height: 100%;
}

.compare {
	cursor: pointer;
	margin: -1px 3px 0 0 !important;
}

.compare img {
	width: 30px;
	height: 35px;
}

.cart-setnum {
	width: 100%;
	margin: 0 auto;
	padding: 5px 0 3px 0;
}

.isFav {
	cursor: pointer;
	width: 30px;
	height: 35px;
	margin-left: 0;
	margin-right: 3px;
}

.isCart {
	cursor: pointer;
	height: 35px;
	width: 30px;
}

.cart {
	margin: 10px 0 0 0;
}

/* For Media (MOBILE) */
/* Ipad(Pro) */
@media(max-width:1050px) {
	.delete_main_icon {
		margin: 10px 0 0 225px;
		padding: 1px;
	}

	._comparase_charac_null_articule {
		width: 244px;
		font-size: 14px;
		padding: 0 2px;
		background-color: rgba(196, 189, 189, 0.438);
		min-height: 60px;
	}

	._comparase_charac_null_value {
		width: 244px;
		height: 60px;
		font-size: 14px;
		padding: 0 2px;
		background-color: rgba(233, 225, 225, 0.527);
	}

	.isCart {
		margin-top: 5px;
		margin-right: 10px !important;
		margin-left: 0;
		margin-right: 0;
		max-height: 38px;
		max-width: 38px;
	}

	/* .modell {
		margin-left: 10px;
	} */
	.card-price {
		font-size: 18px;
		font-weight: 700;
		margin: 0 0 0 10px;
	}

	.old-price {
		font-size: 18px;
		font-weight: 700;
		margin: 0 0 0 10px;
	}

	.compare_right {
		border-radius: 50%;
		margin-right: 3px;
		margin-top: 5px;
		cursor: pointer;
		max-width: 33px;
	}

	.compare_right img {
		width: 100%;
	}

	.compare {
		margin-top: 5px;
		cursor: pointer;
		max-width: 35px;
		max-height: 35px;
	}

	.compare img {
		width: 100%;
	}

	.isFav {
		margin-top: 5px;
		max-width: 38px;
		max-height: 38px;
		margin-left: -3px;
		margin-right: 0;
	}
}

@media(max-width:820px) {
	.delete_main_icon {
		margin: 10px 0 0 225px;
		padding: 1px;
	}

	._comparase_charac_null_articule {
		width: 244px;
		font-size: 14px;
		padding: 0 2px;
		background-color: rgba(196, 189, 189, 0.438);
		min-height: 60px;
	}

	._comparase_charac_null_value {
		width: 244px;
		height: 60px;
		font-size: 14px;
		padding: 0 2px;
		background-color: rgba(233, 225, 225, 0.527);
	}

	.isCart {
		margin-top: 5px;
		margin-right: 10px !important;
		margin-left: 5px;
		margin-right: 0;
		max-height: 38px;
		max-width: 38px;
	}

	/* .modell {
		margin-left: 10px;
	} */
	.card-price {
		font-size: 18px;
		font-weight: 700;
		margin: 0 0 0 10px;
	}

	.old-price {
		font-size: 18px;
		font-weight: 700;
		margin: 0 0 0 10px;
	}

	.compare_right {
		border-radius: 50%;
		margin-right: 9px;
		margin-top: 7px;
		cursor: pointer;
		max-width: 33px;
	}

	.compare_right img {
		width: 100%;
	}

	.compare {
		margin-top: 5px;
		cursor: pointer;
		max-width: 40px;
		max-height: 40px;
	}

	.compare img {
		width: 100%;
		margin: 7px 13px 2px 0;
	}

	.isFav {
		margin-top: 5px;
		max-width: 38px;
		max-height: 38px;
		margin-left: -3px;
		margin-right: 0;
	}

}

/* Surface Duo() */
@media (max-width: 540px) {
	.card-wrapperden {
		width: 200px;
	}

	.compare img {
		width: 100%;
		height: 28px;
		margin: 5px 8px 5px 0;
	}

	.compare {
		margin: 4px 0 0 0 !important;
	}

	.isFav {
		margin-left: 2px;
	}

	.compare_right {
		border-radius: 50%;
		margin-right: 4px;
		margin-top: 7px;
		cursor: pointer;
		max-width: 33px;
	}

	.compare_right img {
		height: 28px;
		margin: 2.1px 8px 5px 0;
	}
}

/* iphone (6/7/8/Plus) */
@media (max-width: 414px) {
	._comparase_charac_null_first_block {
		width: 150px;
		margin: 0;
	}

	._comparase_charac_null {
		width: 150px;
	}

	.card_main_block {
		width: 180px;
		margin: 0 5px;
	}

	.delete_main_icon {
		margin: 10px 0 0 165px;
		padding: 1px;
	}

	.card-wrapperden {
		width: 190px;
		margin-right: 30px;
	}

	._comparase_product_title {
		width: 140px;
		font-size: 11px;
		height: 100px;
		margin: 0 0 0 0;
	}

	._comparase_charac_null_articule {
		width: 140px;
		font-size: 11px;
		padding: 0 2px;
		background-color: rgba(196, 189, 189, 0.438);
		min-height: 45px;
		margin: 0 0 0 5px;
	}

	._compare_null_values {
		font-size: 11px;
	}

	._comparase_charac_null_value {
		width: 170px;
		height: 50px;
		font-size: 11px;
		padding: 0 2px;
		margin: 0 0 0 5px;
		background-color: rgba(233, 225, 225, 0.527);
	}

	.card {
		/* border: none !important; */
		box-shadow: none !important;
		max-width: 190px;
	}

	.card-price {
		font-size: 13px;
		font-weight: 700;
		margin: 0 0 0 0;
	}

	.item-pic-container {
		max-width: 150px;
		margin-left: 1px;
	}

	.old-price {
		font-size: 13px;
		font-weight: 700;
		margin: 0 0 0 10px;
	}

	.compare_right {
		margin-right: 1px;
		cursor: pointer;
		max-width: 30px;
	}

	.compare_right img {
		width: 87.5%;
	}

	.compare {
		cursor: pointer;
		max-width: 25px;
		max-height: 25px;
	}

	.compare img {
		width: 100%;
		margin-right: 10px;
	}

	.isFav {
		max-width: 28px;
		max-height: 28px;
		margin-left: 1px;
		margin-right: 0;
	}

	.isCart {
		margin-right: 10px !important;
		margin-left: 6px;
		margin-right: 0;
		max-height: 28px;
		max-width: 28px;
	}

	/* .modell {
		margin-left: 10px;
	} */

}

@media (max-width: 395px) {
	._comparase_charac_null_first_block {
		width: 125px;
		margin: 0;
	}

	.modell {
		font-size: 12px;
	}

	._comparase_charac_null {
		width: 130px;
	}

	.card_main_block {
		width: 130px;
		margin: 0 5px;
	}

	.delete_main_icon {
		margin: 10px 0 0 125px;
		padding: 1px;
	}

	.card-wrapperden {
		max-width: 150px !important;
	}

	._comparase_product_title {
		margin-top: 0;
		width: 90px;
		font-size: 12px;
		height: 145px;
		margin-left: 20px;
	}

	._comparase_charac_null_articule {
		width: 105px;
		font-size: 11px;
		margin-left: 23px;
		padding: 0 2px;
		background-color: rgba(196, 189, 189, 0.438);
		min-height: 60px;
	}

	._comparase_charac_null_value {
		width: 100px;
		height: 80px;
		font-size: 11px;
		margin-left: 23px;
		padding: 0 2px;
		background-color: rgba(233, 225, 225, 0.527);
	}

	.card {
		/* border: none !important; */
		box-shadow: none !important;
		max-width: 150px;
		margin: 0 0 0 1px;
	}

	.card-price {
		font-size: 14px;
		font-weight: 700;
	}

	.item-pic-container {
		margin-left: 0;
		text-align: center;
		max-width: 150px;
	}

	.item-pic-sick {
		width: 125px;
		margin: 10px auto 0;
	}

	.old-price {
		font-size: 11px;
		font-weight: 700;
	}

	.compare_right {
		cursor: pointer;
		max-width: 20.5px;
		margin-right: 4px;
	}

	.compare_right img {
		width: 100%;
	}

	.compare {
		cursor: pointer;
		max-width: 20px;
		max-height: 20px;
	}

	.compare img {
		width: 100%;
		height: 22px;
		margin: 0 9px 1px 0;
	}

	.btns {
		margin: 0 0 0 0;
	}

	.isFav {
		max-width: 22px;
		max-height: 22px;
		margin-left: 0px;
		margin-right: 4px;
	}

	.isCart {
		margin-left: 0;
		margin-right: 0;
		max-height: 22px;
		max-width: 22px;
	}

	.cart {
		width: 150px !important;
	}

	.cart-setnum {
		width: 150px;
	}
}

/* iphone (6/7/8) */
@media (max-width: 375px) {
	.card_main_block {
		width: 150px;
	}

	._comparase_charac_null_first_block {
		width: 125px;
		margin: 0;
	}

	._comparase_charac_null {
		width: 130px;
	}

	.card_main_block {
		width: 120px;
		margin: 0 5px;
	}

	.delete_main_icon {
		margin: 10px 0 0 125px;
		padding: 1px;
	}

	.item-pic-container img {
		width: 100%;
	}

	.card-wrapperden {
		max-width: 150px !important;
	}

	._comparase_product_title {
		margin-top: 0;
		width: 85px;
		margin-left: 1.5px;
		font-size: 10.5px;
		height: 135px;
	}

	._comparase_charac_null_articule {
		width: 100px;
		font-size: 10px;
		margin: 0 0 0 8px;
		padding: 0 0 0 0;
		background-color: rgba(196, 189, 189, 0.438);
		min-height: 45px;
	}

	._comparase_charac_null_value {
		width: 100px;
		height: 75px;
		font-size: 10px;
		padding: 0 0 0 0;
		margin: 0 0 0 8px;
		background-color: rgba(233, 225, 225, 0.527);
	}

	._compare_null_values {
		font-size: 10px;
	}

	.modell {
		font-size: 10px;
	}

	.card {
		/* border: none !important; */
		box-shadow: none !important;
		border: none;
		max-width: 115px;
	}

	.card-price {
		font-size: 11px;
		font-weight: 700;
	}

	.item-pic-container {
		margin-left: 6px;
		text-align: center;
		max-width: 100px;
	}

	.item-pic-sick {
		width: 100%;
		margin: 10px auto;
	}

	.old-price {
		font-size: 11px;
		font-weight: 700;
	}

	.compare_right {
		cursor: pointer;
		max-width: 20.5px;
	}

	.compare_right img {
		width: 100%;
	}

	.compare {
		cursor: pointer;
		max-width: 20px;
		max-height: 20px;
	}

	.compare img {
		width: 100%;
	}

	.isFav {
		max-width: 22px;
		max-height: 22px;
		margin-left: 0px;
		margin-right: 4px;
	}

	.isCart {
		margin-left: 0;
		margin-right: 0;
		max-height: 22px;
		max-width: 22px;
	}

	.cart {
		width: 150px !important;
	}

	.cart-setnum {
		width: 150px;
	}
}

/* iphone (5/SE) */
@media (max-width: 320px) {}

@media (max-width: 280px) {
	.card-wrapperden {
		max-width: 150px !important;
		margin: 0 -30px 0 0;
	}

	._comparase_product_title {
		margin-top: 0;
		width: 110px;
		font-size: 12px;
		height: 120px;
	}
}