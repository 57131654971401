.cabinet_menu {
	/*margin-top: 2rem;*/
	display: flex;
	justify-content: flex-start;
	/*align-items: flex-start;*/
	/*justify-content: unset;*/
}

.cabinet_menu_profile_camera_imag {
	width: 20px;
}

input[type="file"] {
	display: none;
}

.file-upload {
	top: 55px;
	right: 100px;
	width: 30px;
	height: 30px;
	background: #EA1C24;
	border-radius: 50%;
	color: white;
	outline: none;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.cabinet_menu_profile_items {
	position: relative;
	margin-bottom: 5px;
}

.cabinet_menu_profile_items_flex {
	display: flex;
	align-items: center;
}

.cabinet_menu_profile_items_flex img {
	margin-right: 15px;
	border-radius: 50%;
	width: 80px;
	height: 80px;
}

.cabinet_menu_profile_items_flex p {
	font-weight: 500;
}

.cabinet_menu ul {
	width: 23%;
	background-color: #efefef;
	border-radius: 10px;
	margin-right: 2rem;
	padding: 1.6rem 6rem 1.6rem 2rem;
}

.cabinet_menu ul li {
	list-style-type: none;
	padding: 1rem 0;
	border-bottom: 1px solid #d4d4e1;
}

.cabinet_menu ul li:last-child {
	border: none;
}

.cabinet_menu ul li a {
	font-size: 16px;
	color: black;
	text-decoration: none;
}

@media(max-width:992px) {
	.favorites-inner {
		align-items: center;
	}

	.cabinet_menu {
		flex-direction: column;
	}

	.profile-info {
		flex-direction: column !important;
		align-items: center !important;
		margin-bottom: 2rem;
	}
}