.basket-header {
	justify-content: space-between;
	padding: 1.3rem 1rem;
	border-bottom: 1px solid #d4d4e1;
	display: flex;
}

.basket-header div:first-child {
	width: 40%;
	text-align: left;
}

.basket-header div {
	width: 20%;
	text-align: center;
	color: #606c66;
	font-weight: 500;
}

.item-content {
	width: 40%;
}

.item-content-inner img {
	width: 70px;
	height: 70px;
	border-radius: 10px;
}

.item-content-inner {
	width: 40%;
	text-align: left;
	display: flex;
	align-items: center;
}

.item-content-inner p {
	margin-left: 1.6rem;
}

.cart-item-inner {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #d4d4e1;
	align-items: center;
	padding: 1.3rem 1rem;
}

.item-other-info {
	display: flex;
	width: 60%;
	justify-content: flex-end;
}

.basket-price,
.basket-count,
.basket-delete {
	width: 33%;
	text-align: center;
}

.basket-delete img {
	cursor: pointer;
}

/* FAKE */
.basket-btns-fake {
	margin-top: 15.5rem;
	margin-bottom: 2rem;
}

/* ORIGINAL */
.basket-btns {
	margin-top: 2.3rem;
	padding: 0 1rem;
	margin-bottom: 2rem;
}

.basket-btns-inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.make-order {
	width: 260px;
	height: 60px;
	background: #EA1C24;
	border-radius: 10px;
	color: white;
	outline: none;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-right: 1rem;
}

.basket-links {
	display: flex;
	align-items: center;
	justify-content: center;
}

.basket-count {
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
}

.basket-increase,
.basket-decrease {
	cursor: pointer;
}


/* For Media (MOBILE) */

/* Ipad(Pro) */
@media(max-width:1050px) {}

/* 768() */
@media(max-width: 820px) {}

/* Surface Duo() */
@media (max-width: 540px) {
	.cart-item-title {
		font-size: 14px;
		text-align: left;
	}
}

/* iphone (6/7/8/Plus) */
@media (max-width: 414px) {
	.basket-header div:nth-child() {
		display: none;
	}

	.basket-btns-inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}

	.item-other-info {
		display: flex;
	}

	.cart-item-title {
		font-size: 14px;
		text-align: left;
		position: absolute;
		right: 0;
		left: 80px;
	}

	.basket-price {
		padding-bottom: 120px;
		font-size: 15px;
		margin-top: 5px;
		margin-left: 10px;
	}

	.basket-count {
		margin-top: 5px;
	}

	.basket-delete {
		margin-top: 10px;
	}

	.basket-links {
		display: flex;
		flex-wrap: wrap;
	}

	.overall-price {
		margin-left: 95px;
		margin-bottom: 10px;
	}

	.back-to-shopping {
		margin-top: 10px;
		margin-right: 15px;
	}
}

@media (max-width: 395px) {
	.basket-header div:nth-child() {
		display: none;
	}

	.basket-btns-inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}

	.item-other-info {
		display: flex;
	}

	.cart-item-title {
		font-size: 14px;
		text-align: left;
	}

	.basket-price {
		padding-bottom: 120px;
		font-size: 15px;
		margin-top: 5px;
		margin-left: 0;
	}

	.basket-count {
		margin-top: 5px;
	}

	.basket-delete {
		margin-top: 5px;
	}

	.basket-links {
		display: flex;
		flex-wrap: wrap;
	}

	.overall-price {
		margin-left: 45px;
		margin-bottom: 10px;
	}

	.back-to-shopping {
		margin-top: 10px;
		margin-right: 15px;
	}
}

/* iphone (6/7/8) */
@media (max-width: 375px) {
	.basket-header div:nth-child() {
		display: none;
	}

	.basket-btns-inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}

	.item-other-info {
		display: flex;
	}

	.cart-item-title {
		font-size: 14px;
		text-align: left;
	}

	.basket-price {
		padding-bottom: 120px;
		font-size: 15px;
		margin-top: 5px;
		margin-left: 0;
	}

	.basket-count {
		margin-top: 5px;
	}

	.basket-delete {
		margin-top: 5px;
	}

	.basket-links {
		display: flex;
		flex-wrap: wrap;
	}

	.overall-price {
		margin-left: 45px;
		margin-bottom: 10px;
	}

	.back-to-shopping {
		margin-top: 10px;
		margin-right: 15px;
	}
}

/* iphone (5/SE) */
@media (max-width: 320px) {}

/* latest media */
@media (max-width: 280px) {
	.make-order {
		width: 230px;
		margin-left: 7px;
	}

	.basket-header div:nth-child() {
		display: none;
	}

	.basket-btns-inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}

	.item-other-info {
		display: flex;
	}

	.cart-item-title {
		font-size: 13px;
		text-align: left;
	}

	.basket-price {
		padding-bottom: 120px;
		font-size: 15px;
		margin-top: 5px;
		margin-left: 0;
	}

	.basket-count {
		margin-top: 5px;
	}

	.basket-delete {
		margin-top: 5px;
	}

	.basket-links {
		display: flex;
		flex-wrap: wrap;
	}

	.overall-price {
		margin-left: 40px;
		margin-bottom: 10px;
	}

	.back-to-shopping {
		margin-top: 10px;
		margin-right: 20px;
	}
}