.whatsapp {
	display: none;
	width: 60px;
	height: 60px;
	position: fixed;
	bottom: 45px;
	right: 30px;
	z-index: 1;
}

.whats-image {
	width: 100%;
}

.whatsaapp {
	width: 66px;
	height: 66px;
	position: fixed;
	bottom: 40px;
	right: 27px;
	z-index: 1;
}

.whaats-image {
	width: 100%;
}

* {
	font-size: 16px;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@media(max-width:767px) {
	.container {
		max-width: 600px !important;
		padding: 0 2.5px;
	}

	.whatsapp {
		display: block;
	}

	.whatsaapp {
		display: none;
	}
}

@media(max-width:540px) {
	.whatsapp {
		bottom: 80px;
		right: 23px;
	}
}

@media(max-width:414px) {
	.whatsapp {
		bottom: 80px;
		right: 24px;
	}
}

@media(max-width:400px) {
	.delivery-zones-title {
		padding: 0 !important;
	}

	.whatsapp {
		bottom: 80px;
		right: 24px;
	}

	.delivery-zones-title * {
		font-size: 12px;
	}

	.delivery-zone * {
		font-size: 12px;
	}

	.delivery-zone {
		padding: 0 !important;
	}

	.delivery-map-title {
		flex-direction: column !important;
		align-items: center !important;
		justify-content: space-between !important;
	}

	.delivery-map-title img {
		width: 200px;
	}

	.containerz {
		max-width: 300px !important;
	}
}