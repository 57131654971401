form input:not(.radio):not(.radio-receive){
        cursor: pointer;
    outline: none;
    /* width: 100%; */
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    padding: 0.5rem 0.5rem;
}
.order-field-title{
    font-size: 16px;
    font-weight: 700;
}
.order-info{
    width: 60%;
}
.order-inner{
    margin-bottom: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.select-container{
    border-top: 1px solid #d4d4e1;
    padding: 0.5rem 0;
}
.select-container, .select-container p {
    display: flex;
    align-items: center;
}
form div p{
    display: flex;
    flex-direction: column;
}
.commentary{
    outline: none;
    /* width: 100%; */
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    padding: 0.5rem 2rem;
}
.your-info *:not(h4){
    margin-bottom: 1rem;
}
.select-container p:first-child{
    margin-right: 0.5rem;
}
.order-items{
    background-color: #e5e5e5;
    padding: 1.5rem 1.7rem;
    border-radius: 10px;
    width:33%;
    height: fit-content;
}
.basket-item-list{
    display: flex;
    align-items: flex-start;
    padding-top: 1rem;
}
.filter-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.filters{
    display: flex;
    align-items: center;
    flex-direction: row;
}
.filters-active{
    color: rgba(237, 28, 36, 1);
    background: #FFFFFF !important;
    box-shadow: 0px 1px 2px #F4F3F8, 0px 2px 4px #F4F3F8 !important;
}
.filters div{
    cursor: pointer;
    font-size: 14px;
    width: 85px;
    height: 40px;
    background: #F4F3F8;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center ;
    /* padding: 1rem; */
    /* margin-right: 1rem; */
}
.basket-item-list:not(:last-child){
    border-top: 1px solid #8080802b;
    margin-bottom: 1rem;
}
.basket-info{
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 0 1rem;
}
.count-price{
    display: flex;
    margin-top: auto;
    justify-content: space-between;
}
.basket-sum, .basket-delivery, .basket-overall{
    border-top: 1px solid #8080802b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}
.basket-sum, .basket-delivery, .basket-overall p{
    font-size: 16px;
    font-weight: 400;
}
.success-modal{
    z-index: 10000000000;
    position: fixed;
    bottom: 10px;
    padding: 0.5rem;
    color: white;
    left: 50%;
    background: #01cb01;
    border-radius: 10px;
}
.err-modal{
    z-index: 10000000000;
    position: fixed;
    bottom: 10px;
    padding: 0.5rem;
    color: white;
    left: 50%;
    background: #ff0606;
    border-radius: 10px;
}



/* For Media (MOBILE) */

/* Ipad(Pro) */
@media(max-width:1050px){
 }
 
 @media(max-width:820px){
	.order-inner {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
	}
	.make-order {
		margin: 15px auto;
	}
	.commentary {
		width: 100%;
	}
 }
 
 /* Surface Duo() */
 @media (max-width: 540px) {
	.order-inner {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
	}
	.make-order {
		margin: 15px auto;
	}
	.commentary {
		width: 100%;
	}
	.adress_for_place_information {
		margin-left: -80px;
	}
	.adress_date {
		margin-left: -80px;
	}
	.adress_place {
		margin-left: -80px;
	}
	.conditions {
		margin-left: -80px;
	}
	.your-info {
		margin-left: -80px;
	}
	.way_of_buy {
		margin-left: -80px;
	}
	.order-items {
		width: 450px;
		margin: 0 auto;
	}
 } 
 
 /* iphone (6/7/8/Plus) */
  @media (max-width: 414px) {
	.order-inner {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
	}
	.make-order {
		width: 250px;
		margin: 15px auto;
	}
	.commentary {
		width: 80%;
	}
	.order-items {
		width: 350px;
		margin: 0 auto;
	}
	.basket-item-list {
		padding: 0 0 60px 0;
	}
	.adress_for_place_information {
		margin-left: -70px;
	}
	.adress_date {
		margin-left: -70px;
	}
	.adress_place {
		margin-left: -70px;
	}
	.conditions {
		margin-left: -70px;
	}
	.your-info {
		margin-left: -70px;
	}
	.way_of_buy {
		margin-left: -70px;
	}
 }
 
 @media (max-width: 395px) {
	.order-inner {
		display: flex;
		flex-wrap: wrap;
	}
	.make-order {
		width: 220px;
		margin: 15px auto;
	}
	.commentary {
		width: 80%;
	}
	.order-items {
		width: 325px;
		margin: 0 auto;
	}
	.basket-item-list {
		padding: 0 0 70px 0;
	}
	.adress_for_place_information {
		margin-left: -70px;
	}
	.adress_date {
		margin-left: -70px;
	}
	.adress_place {
		margin-left: -70px;
	}
	.conditions {
		margin-left: -70px;
	}
	.your-info {
		margin-left: -70px;
	}
	.way_of_buy {
		margin-left: -70px;
	}
 }
 
 /* iphone (6/7/8) */
  @media (max-width: 375px) {
	.order-inner {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
	}
	.make-order {
		width: 200px;
		margin: 15px auto;
	}
	.commentary {
		width: 80%;
	}
	.order-items {
		width: 300px;
		margin: 0 auto;
	}
	.basket-item-list {
		padding: 0 0 70px 0;
	}
	.adress_for_place_information {
		margin-left: -60px;
	}
	.adress_date {
		margin-left: -60px;
	}
	.adress_place {
		margin-left: -60px;
	}
	.conditions {
		margin-left: -60px;
	}
	.your-info {
		margin-left: -60px;
	}
	.way_of_buy {
		margin-left: -60px;
	}
 } 
 
 /* iphone (5/SE) */
  @media (max-width: 320px) {
	.order-items {
		width: 240px;
		margin: 0 auto;
	}
 } 
 