.awssld__content>img {
	position: static;
	object-fit: contain;
	background-color: #ffffff;
}

.awssld__content>img,
.awssld__content>video {
	position: static;
}

.awssld__controls__arrow-left:after,
.awssld__controls__arrow-right:after {
	background-color: #FF2400;
}

.awssld__controls__arrow-left:before,
.awssld__controls__arrow-right:before {
	background-color: #FF2400;
}