footer {
	color: white;
	height: 370px;
	background: #040708;
}

.footer-wrap {
	/* position: absolute; */
	justify-content: space-between;
	padding-top: 70px;
	display: flex;
	flex-direction: row;
	margin-top: 75px;
}

.logo-footer {
	max-width: 240px;
}

.info,
.additional,
.support {
	display: flex;
	flex-direction: column;
}

.info-title,
.additional-title,
.support-title,
.icons-title {
	font-size: 18px;
	font-weight: 700;
}

.info-item,
.additional-item,
.support-item {
	cursor: pointer;
}

.info-item:not(:last-child),
.additional-item:not(:last-child),
.support-item:not(:last-child) {
	margin-bottom: 1rem;
}

.icons-wrap a:not(:last-child) {
	margin-right: 20px;
}

.bottom-content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

@media(max-width:767px) {

	.info-title,
	.additional-title,
	.support-title,
	.icons-title {
		font-size: 14px;
		font-weight: 700;
	}

	.logo-footer {
		margin: 0 0 0 5px;
	}

	.info {
		margin: 0 0 0 5px;
	}

	.additional {
		margin: 0 0 0 5px;
	}

	.support {
		margin: 0 0 0 5px;
	}

	.icons {
		margin: 0 0 0 5px;
	}

	.a-lux {
		margin: 0 5px 0 0;
	}
}