.died_block {
	display: none;
}

.__main_image_modal {
	display: flex;
	justify-content: center;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: #222121e1;
	z-index: 3;
}

.__main_image_modal_siba {
	width: 80%;
	height: 80%;
	position: relative;
	z-index: 4;
}

.auto__rating {
	border-radius: 0px 13px 13px 13px;
	text-align: center;
	padding: 5px 0;
	background-color: #80808081;
	left: 192px;
	top: 35px;
	position: absolute;
	width: 100%;
}

.main-image {
	cursor: pointer;
	width: 100%;
	height: 100%;
	object-fit: contain;
	background-color: #fff;
}

.__main_dark_imag_close {
	top: 0;
	right: 0;
	cursor: pointer;
	position: absolute;
	z-index: 5;
	width: 40px;
	height: 40px;
}

.modal-bg {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: #222121e1;
	z-index: 3;
}

.modal-comment-inner {
	text-align: center;
	width: 400px;
	margin-top: 128px;
}

.make-order {
	margin-top: 20px;
	margin-left: 75px;
}

.cross-img {
	cursor: pointer;
	width: 25px;
	margin: 20px 0 -20px 360px;
}

.comment-title-input {
	background-color: #fff;
	margin: 0 0 10px 0;
	border-radius: 10px;
	width: 90%;
	padding: 15px 0 15px 8px;
}

.withoutMarginFakeName {
	background-color: #fff;
	margin: 0 0 0 0;
	border-radius: 10px;
	width: 90%;
	padding: 15px 0 15px 8px;
}

.comment-rating-input {
	border-radius: 10px;
	width: 90%;
	padding: 15px 0 15px 8px;
	margin: 0 0 10px 0;
}

.withoutMarginFakeNumber {
	border-radius: 10px;
	width: 90%;
	padding: 15px 0 15px 8px;
	margin: 0 0 0 0;
}

.comment {
	border-radius: 10px;
	width: 90%;
	padding: 15px 0 15px 8px;
	margin: 0 0 10px 0;
}

.withoutMarginFakeComment {
	border-radius: 10px;
	width: 80%;
	padding: 15px 0 15px 8px;
	margin: 0 0 0 0;
}

._mini_sliders {
	width: 200px;
}

.extra {
	display: none;
}

.main-photo-childrens {
	display: flex;
	justify-content: space-around;
}

.main-photo-children {
	width: 100px;
	height: 100px;
}

.main-photo-child {
	width: 26.5%;
	border: 1px solid #D7D4E4;
	border-radius: 10px;
	cursor: pointer;
}

.rating {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.rating-container-flex {
	display: flex;
	align-items: center;
	position: relative;
}

._products_characters {
	display: flex;
}

._product_articule {
	font: bold;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	color: #000000;
	line-height: 30px;
}

._product_value {
	margin-left: 10px;
	font: bold;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	color: #000000;
}

.rating-container {
	display: flex;
	align-items: center;
}

.main-photo {
	border: 1px solid #D7D4E4;
	border-radius: 10px;
	text-align: center;
	margin: 17px;
}

.main-photo img {
	width: 95%;
	object-fit: contain;
	height: 100%;
}

.rating-inner {
	margin: 0 0 0 7px;
}

.rating-inner img {
	margin: 0 1.5px 0 1.5px;
}

.price {
	font-size: 36px;
	font-weight: 700;
}

.old-price {
	text-decoration: line-through;
	font-size: 15px;
	color: #808080 !important;
	font-weight: 400;
}

.card-sale {
	color: rgba(237, 28, 36, 1) !important;
}

.prices {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.sale-modal {
	background: #ED1C24;
	border-radius: 4px;
	/* position: absolute; */
	color: white;
	font-size: 16px;
	padding: 2px 17px;
}

.count {
	align-items: center;
	display: flex;
	margin-top: 35px;
}

.count div:not(:last-child) {
	margin-right: 16px;
}

.increase-count,
.decrease-count {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	border-radius: 10px;
}

.decrease-count {
	background: #F4F3F8;
}

.increase-count {
	color: white;
	background: #EA1C24;
}

.add-cart-btn {
	width: 260px;
	height: 60px;
	background: #EA1C24;
	border-radius: 10px;
	color: white;
	outline: none;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-top: 40px;
	font-weight: 600;
}

.remove-cart-btn {
	width: 260px;
	height: 60px;
	background: #e4e4e4;
	border-radius: 10px;
	color: black;
	outline: none;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-top: 40px;
}

.product-line {
	background: #222222;
	mix-blend-mode: normal;
	opacity: 0.2;
}

.product-info-inner {
	width: 48.5%;
	margin-left: 45px;
}


.product-photos {
	width: 50%;
	height: 400px;
}

._mini_sliders {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

._mini_sliders_imag {
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
	border-radius: 10px;
	cursor: pointer;
	margin: 8px 2px 0 2px;
	width: 150px;
	height: 150px;
}


.product-info {
	display: flex;
	flex-direction: row;
}

.char-title {
	font-size: 22px;
	font-weight: 700;
}

.product-description-title,
.reviews-title {
	font-size: 24px;
}

.reviews-count {
	font-size: 16px;
	color: rgba(57, 55, 77, 0.5);
}

.similar-products {
	justify-content: space-between;
	display: flex;
	flex-direction: row;
}

.latest-seen-prod {
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
}

/* .latest-seen-prod div:not(:last-child){
    margin-right: 2rem;
} */
@media(max-width: 400px) {
	.modal-comment-inner {
		width: 300px !important;
	}
}

@media(max-width:992px) {
	.rating-comm-inner {
		display: flex;
	}

	.comment-text {
		padding-left: 10px !important;
	}

	.make-adapt {
		width: 120px !important;
	}

	.rating-container {
		flex-direction: column;
	}

	.rating-inner {
		margin: 0;
	}

	.product-photos {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: center;
	}

	.product-info-inner {
		width: 100%;
	}

	.product-info {
		flex-direction: column;
		align-items: center;
	}

	.price {
		text-align: center;
	}

	.count {
		justify-content: center;
	}

	.product-line {
		width: 290px !important;
		margin: 0 auto;
	}

	.latest-seen-prod div,
	.similar-products div {
		margin: 0;
		margin-right: 0 !important;
		margin-bottom: 1rem;
	}

	.latest-seen-prod {
		justify-content: center;
		flex-wrap: wrap;
	}

	.similar-products {
		justify-content: center;
		flex-wrap: wrap;
	}
}

/* For Media (MOBILE) */

/* Ipad(Pro) */
@media(max-width:1050px) {
	.product-info-inner {
		width: 100%;
		margin: 0;
	}

	.similar-products {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
	}

	.add-cart-btn {
		width: 250px;
		height: 60px;
		display: flex;
		justify-content: center;
		margin-top: 40px;
		font-weight: 600;
	}

	.remove-cart-btn {
		width: 250px;
		height: 60px;
		display: flex;
		justify-content: center;
		margin-top: 40px;
	}

	.mob-product {
		margin: 0 40px;
	}

	.product-photos {
		margin: 0 9px 0 0;
	}
}

@media(max-width:920px) {
	.product-photos {
		margin: 0 0 0 0;
	}

	.product-photos {
		display: block;
	}

	.product-info-inner {
		margin-top: 380px;
	}

	._mini_sliders {
		justify-content: space-around;
	}

	._mini_sliders_imag {
		width: 220px;
		height: 220px;
	}
}

@media(max-width: 820px) {
	.__main_image_modal_siba {
		top: 190px;
	}

	.auto__rating {
		border-radius: 13px 13px 13px 0;
		text-align: center;
		padding: 5px 0;
		margin: -55px 0 0 0;
		background-color: #80808081;
		left: 182px;
		top: 0;
		position: absolute;
		width: 100%;
	}

	.container {
		padding: 0 2.5px;
	}

	.__main_image_modal img {
		width: 99%;
		object-fit: contain;
	}
}

@media(max-width: 820px) {
	.__main_dark_imag_close {
		right: -303px;
	}

	.product-info-inner {
		width: 100%;
		margin: 0;
	}

	.similar-products {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
	}

	.mob-product {
		width: 280px;
	}

	.product-info-inner {
		margin-top: 300px;
	}

	._mini_sliders {
		justify-content: space-around;
	}

	._mini_sliders_imag {
		width: 200px;
		height: 200px;
	}

	.product-photos {
		margin: 0 0 0 0;
	}

	.comment {
		width: 76%;
		margin-left: 2px;
	}
}

@media (max-width: 768px) {
	.__main_dark_imag_close {
		right: -282px;
	}
}

/* Surface Duo() */
@media (max-width: 540px) {

	.__main_dark_imag_close {
		right: -195px;
	}

	.__main_image_modal_siba {
		top: 190px;
	}

	.auto__rating {
		border-radius: 13px 13px 13px 13px;
		text-align: center;
		padding: 5px 0;
		margin: -20px 0 0 0;
		background-color: #80808081;
		left: 182px;
		top: 0;
		position: absolute;
		width: 100%;
	}

	.mob-product {
		width: 250px;
	}

	.__main_image_modal img {
		width: 100%;
	}

	.similar-products {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
	}

	.product-title {
		margin-top: 50px;
	}

	.rating {
		margin: -10px 0 0 0;
	}

	.product-photos {
		display: block;
		margin: 0 0 0 0;
	}

	._mini_sliders_imag {
		width: 110px;
		height: 110px;
	}

	.comment {
		width: 365px;
		padding: 6px 0 6px 3px;
	}
}

/* iphone (6/7/8/Plus) */
@media (max-width: 414px) {

	.__main_dark_imag_close {
		right: -145px;
	}

	.auto__rating {
		font-size: 11px;
		border-radius: 13px 13px 13px 13px;
		text-align: center;
		padding: 5px;
		margin: -23px 0 0 0;
		background-color: #80808081;
		left: 182px;
		top: 0;
		position: absolute;
		width: 100%;
	}

	.container {
		padding: 0 5px;
	}

	.product-info-inner {
		width: 100%;
		margin: 0;
	}

	.mob-product {
		width: 200px;
	}

	.similar-products {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
	}

	.product-title {
		margin-top: 50px;
	}

	.rating {
		margin: -10px 0 0 0;
	}

	.add-cart-btn {
		width: 200px;
		height: 60px;
		display: flex;
		justify-content: center;
		margin-top: 40px;
		font-weight: 600;
	}

	.remove-cart-btn {
		width: 200px;
		height: 60px;
		display: flex;
		justify-content: center;
		margin-top: 40px;
	}

	.product-photos {
		display: block;
		margin: 0 0 0 0;
	}

	._mini_sliders_imag {
		width: 100px;
		height: 100px;
	}
}

@media (max-width: 395px) {
	.__main_dark_imag_close {
		right: -135px;
	}

	.cross-img {
		margin: 20px 0 -30px 280px;
	}

	.product-info-inner {
		width: 100%;
		margin: 0;
	}

	.similar-products {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
	}

	.mob-product {
		width: 150px;
		display: flex;
	}

	.product-title {
		margin-top: 50px;
	}

	.compare {
		margin-top: 5px !important;
	}

	.rating {
		margin: 0;
	}

	.add-cart-btn {
		width: 185px;
		height: 60px;
		margin-top: 20px;
		font-weight: 500;
		margin-left: 0;
	}

	.remove-cart-btn {
		margin-right: 5px;
		width: 185px;
		height: 60px;
		margin-top: 20px;
	}

	.comment {
		width: 278px;
		padding: 6px 0 6px 3px;
	}
}

/* iphone (6/7/8) */
@media (max-width: 375px) {

	.__main_dark_imag_close {
		right: -130px;
	}

	.auto__rating {
		font-size: 11px;
		border-radius: 13px 13px 13px 13px;
		text-align: center;
		padding: 5px;
		margin: -25px 0 0 0;
		background-color: #80808081;
		left: 162px;
		top: 0;
		position: absolute;
		width: 100%;
	}

	.cross-img {
		margin: 20px 0 -30px 290px;
	}

	.product-info-inner {
		width: 100%;
		margin: 0;
	}

	.add-cart-btn {
		width: 170px;
		height: 60px;
		margin-top: 20px;
		font-weight: 500;
		margin-left: 5px;
	}

	.remove-cart-btn {
		margin-right: 5px;
		width: 170px;
		height: 60px;
		margin-top: 20px;
	}

	.prices {
		width: 120px;
	}
}

/* iphone (5/SE) */
@media (max-width: 320px) {
	.__main_dark_imag_close {
		right: -113px;
		width: 30px;
		height: 30px;
	}

	.auto__rating {
		font-size: 11px;
		border-radius: 13px 13px 13px 13px;
		text-align: center;
		padding: 5px;
		margin: -25px 0 0 0;
		background-color: #80808081;
		left: 117px;
		top: 0;
		position: absolute;
		width: 100%;
	}

	.product-info-inner {
		width: 100%;
		margin: 0;
	}

	.cross-img {
		margin: 20px 0 -30px 230px;
	}

	.make-order {
		margin: 1px auto;
	}

	.add-cart-btn {
		width: 150px;
	}

	.prices {
		width: 120px;
	}
}

/* latest media */
@media (max-width: 280px) {
	.product-info-inner {
		width: 100%;
		margin: 0;
	}

	.add-cart-btn {
		width: 125px;
		height: 60px;
		display: flex;
		justify-content: center;
		margin-top: 40px;
		font-weight: 600;
	}

	.remove-cart-btn {
		width: 125px;
		height: 60px;
		display: flex;
		justify-content: center;
		margin-top: 40px;
	}
}