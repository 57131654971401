.comp {
	min-height: 100vh;
}

.comp-search {
	cursor: pointer;
	outline: none;
	width: 90%;
	border: 1px solid #d5d5d5;
	border-radius: 10px;
	padding: 0.5rem 2rem;
}

.search-item {
	display: flex;
	margin: 0 auto;
	justify-content: flex-start;
	align-items: flex-end;
}

.search-dropdown {
	z-index: 1;
	padding: 2rem;
	-webkit-box-shadow: 0px 10px 8px 6px rgba(34, 60, 80, 0.2);
	-moz-box-shadow: 0px 10px 8px 6px rgba(34, 60, 80, 0.2);
	box-shadow: 0px 10px 8px 6px rgba(34, 60, 80, 0.2);
	padding: 2rem;
	box-shadow: 0px 10px 8px 6px rgb(34 60 80 / 20%);
	background: white;
	position: absolute;
	/* left: 0; */
	width: 68%;
}

.search-item:not(:first-child) {
	margin-top: 2rem;
}

.search-item:not(:last-child) {

	border-bottom: 1px solid black;
}

.comp-characs {
	display: flex;
}

.photo-and-title {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.photo-and-title img {
	width: 100px;
	object-fit: contain;
}

.comp-text {
	margin-left: 4rem;
}

.add-to-comp {
	cursor: pointer;
	margin-left: auto;
	margin-bottom: 1rem;
	width: 110px;
	background-color: #10ff00;
	color: black;
	outline: none;
	border: none;
	border-radius: 4px;
	font-size: 14px;
	padding: 0.5rem 0 0.5rem 0;
}

.comp-items {
	display: flex;
	justify-content: center;
	margin-top: 2rem;
}

/* For Media (MOBILE) */

/* Ipad(Pro) */
@media(max-width:1050px) {}

@media(max-width:820px) {}

/* Surface Duo() */
@media (max-width: 540px) {
	.comp-items {
		display: flex;
		justify-content: space-between;
	}
}

/* iphone (6/7/8/Plus) */
@media (max-width: 414px) {
	.search-comparison {
		width: 405px;
	}

	.comp-items {
		display: flex;
		justify-content: space-around;
	}
}

@media (max-width: 395px) {
	.search-comparison {
		width: 385px;
		display: block;
	}

	.comp-items {
		margin: 0 29px;
		flex-wrap: wrap;
		display: flex;
		justify-content: space-around;
	}
}

/* iphone (6/7/8) */
@media (max-width: 375px) {
	.search-comparison {
		width: 350px;
		display: flex;
		margin-left: 7px;
	}

	.comp-items {
		width: 350px;
		margin: 0;
		flex-wrap: wrap;
		display: flex;
		justify-content: space-around;
	}
}

/* iphone (5/SE) */
@media (max-width: 320px) {}

@media (max-width: 280px) {
	.search-comparison {
		width: 270px;
	}

	.comp-items {
		width: 152px;
		margin: 0 auto;
		display: block;
		justify-content: center;
	}
}