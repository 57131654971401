.catalog_pagination_inner{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.pagination{
    display: flex;
    flex-direction: row;
    padding: 0 40px;
}
.page-item{
    list-style-type: none;
    padding: 1rem;
    border-radius: 4px;
}
.page-link{
    font-weight: 700;
    color: black;
    text-decoration: none;
}
.active-pagination{
    background-color: red;
}
.active-pagination a{
    color: white;
}


/* NEWS */
.fill {
	display: none;
}
.toFromText {
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 20px;
}
.numbers {
	cursor: pointer;
	border: none;
	padding: 15px;
	margin: 0 5px;
	border-radius: 7px;
	font-weight: 600;
	font-size: 20px;
	background-color: aliceblue;
}

.isActive {
	background-color: red;
}