header * {
  font-size: 14px;
}

.container {
  height: 100%;
  max-width: 1150px;
  margin: 0 auto;
}

.img-red-border {
  border-radius: 50%;
  border: 3.8px solid rgb(216, 21, 21);
  padding: 5px;
  position: relative;
  margin-right: 1rem;
  background-color: rgb(216, 21, 21);
}

.phone-numbers-cont,
.service-center,
.user,
.phone-numbers {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.phone-numbers *:not(:last-child) {
  margin-right: 5px;
}

.line {
  border: 1px solid #ffffff;
  transform: rotate(90deg);
  background-color: white;
  width: 21.83px;
  height: 0px;
}

.header-wrap {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.phone-numbers a {
  text-decoration: none;
  color: white;
}

header {
  /* z-index: 3 !important;
	position: fixed; */
  color: white;
  height: 60px;
  width: 100%;
  background-color: #040708;
}

.header-bottom {
  padding: 8px 0;
  text-align: center;
  background-color: rgb(65, 65, 65);
  color: #fff;
}

.img-border {
  position: relative;
  margin-right: 1rem;
  padding: 0.5rem;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.2);
}

.img_border_img {
  text-align: center;
  position: relative;
  top: 1px;
  right: 1px;
}

.img_border_i {
  text-align: center;
  position: relative;
  top: 2px;
  right: 0.5px;
}

.img_border_m {
  text-align: center;
  position: relative;
  left: 0.5px;
}

.nav,
.logo,
.mid-bar {
  display: flex;
  flex-direction: row;
}

.mid-bar {
  height: 110px;
  color: black;
  align-items: center;
  justify-content: space-between;
}

.official-site {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 20px;
}

.official-site > p {
  color: rgba(178, 178, 178, 1);
  margin: 0;
}

.nav-link:not(:last-child) {
  margin-right: 40px;
}

.search-input {
  padding: 0 0 0 10px;
  margin-right: 1rem;
  width: 235px;
  height: 35px;
  background: #f4f3f8;
  border: none;
  outline: none;
}

.search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cat-arrow {
  margin-right: 2rem;
}

.first_number {
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
}

.second_number {
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
}

.below_service_number {
  font-style: normal;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
}

.below_first_number {
  text-decoration: none;
  color: white;
  font-style: normal;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
}

.below_second_number {
  text-decoration: none;
  color: white;
  font-style: normal;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
}

._basket_integraition_none {
  display: none;
}

._basket_integraition {
  color: white;
  position: absolute;
  right: -4px;
  top: -10px;
  border: 1px solid #d34511;
  border-radius: 50%;
  background: #d34511;
  font-weight: 900;
  padding: 0 4px;
}

/* For Media (MOBILE) */

/* Ipad(Pro) */
@media (max-width: 1050px) {
  /* DONT TUCH!!! */
  .payment-type {
    width: 100% !important;
  }

  .card {
    margin-right: 0.1rem;
  }

  .right-quality-wrap {
    padding: 0;
  }

  .mid-bar {
    justify-content: space-around;
  }

  .nav {
    display: none;
  }
}

@media (max-width: 820px) {
  .right-quality-wrap {
    padding: 1rem !important;
  }

  .mid-bar {
    padding-top: 130px;
    margin-bottom: -20px;
  }

  .search-input {
    width: 100px;
  }

  .official-site {
    display: none;
  }

  .user {
    margin-bottom: 1rem;
    flex-wrap: wrap;
  }

  header {
    height: 145px;
    z-index: 4 !important;
    position: fixed;
  }

  .header-wrap {
    flex-direction: column;
  }

  .phone-nums-cont {
    flex-direction: column;
  }

  .first_number {
    margin-top: 5px;
  }

  .second_number {
    margin-top: 5px;
  }

  .phone-numbers img {
    margin: 6px 0 0 2px;
  }

  .below_service_number {
    margin-top: 14px;
  }

  .below_first_number {
    margin-right: 8px;
  }

  /* .user {
	  margin: -7px 0 0;
  } */
}

/* Surface Duo() */
@media (max-width: 540px) {
}

/* iphone (6/7/8/Plus) */
@media (max-width: 414px) {
  .phone-numbers img {
    margin: 10px 0 0 2px;
  }

  .first_number {
    margin-top: 10px;
    font-size: 14px;
  }

  .below_service_number {
    margin-top: 12px;
  }

  .second_number {
    margin-top: 10px;
    font-size: 14px;
  }

  .below_first_number {
    font-size: 14px;
    margin-right: 8px;
  }

  .below_second_number {
    font-size: 14px;
  }

  header * {
    font-size: 10px;
  }

  .mid-bar {
    padding-top: 145px;
    margin-bottom: -20px;
    flex-direction: column;
  }
}

@media (max-width: 395px) {
  .phone-numbers img {
    margin: 3px 0 0 2px;
  }

  .first_number {
    margin-top: 3px;
    font-size: 14px;
    text-align: center;
  }

  .second_number {
    margin-top: 3px;
    font-size: 14px;
    text-align: center;
  }

  .below_service_number {
    margin-top: 14px;
    font-size: 14px;
    text-align: center;
  }

  .below_first_number {
    margin-right: 8px;
    margin-top: 3px;
    font-size: 14px;
    text-align: center;
  }

  .below_second_number {
    margin-top: 3px;
    font-size: 14px;
    text-align: center;
  }

  /* .user {
		
	} */
}

/* iphone (6/7/8) */
@media (max-width: 375px) {
  .phone-numbers img {
    margin: 4px 0 0 2px;
  }

  .first_number {
    margin-top: 3px;
    font-size: 12px;
    text-align: center;
  }

  .second_number {
    margin-top: 3px;
    font-size: 12px;
    text-align: center;
  }

  .below_service_number {
    margin-top: 16.1px;
    font-size: 14px;
    text-align: center;
  }

  .below_first_number {
    margin-top: 3px;
    margin-right: 8px;
    font-size: 12px;
    text-align: center;
  }

  .below_second_number {
    margin-top: 3px;
    font-size: 12px;
    text-align: center;
  }
}

/* iphone (5/SE) */
@media (max-width: 320px) {
  .phone-numbers img {
    margin: 0 0 0 2px;
  }

  .first_number {
    margin-top: 0;
    text-align: center;
    font-size: 10.9px;
  }

  .second_number {
    margin-top: 0;
    text-align: center;
    font-size: 10.9px;
  }

  header * {
    font-size: 10px;
  }

  .mid-bar {
    flex-direction: column;
  }
}
