.favorites-inner{
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	width: 100%;
	position: relative;
}
.favorites{
	margin: 1rem 0 0 1.5rem;
	width: 100%;
}
.product_Title {
	font-weight: 600;
	font-size: 30px;
	color: #888282;
}

.none_Product {
	margin-top: 15rem;
	font-weight: 600;
	font-size: 30px;
	color: #888282;
	margin-bottom: 15rem;
}
/* For Media (MOBILE) */

/* Ipad(Pro) */
@media(max-width:1050px){
	/* DONT TUCH!!! */
	.favorites {
		width: 550px;
		margin: 0 auto;
	}
	.favorites-inner{
		display: flex;
		width: 550px;
		margin: 0 auto;
		justify-content: space-evenly;
		flex-wrap: wrap;
	}
 }
 
 @media(max-width:820px){
	.favorites {
		margin: 0 auto;
		width: 700px;
  }
	.favorites-inner{
		display: flex;
		width: 700px;
		margin: 1rem auto auto 0;
		justify-content: center;
		flex-wrap: wrap;
	}
	.none_Product {
		margin-top: 5rem;
		margin-bottom: 5rem;
	}
}
 
 /* Surface Duo() */
 @media (max-width: 540px) {
	.favorites {
		margin: 0 auto;
		width: 535px;
  }
  .favorites-inner {
	  padding: 0 0 0 18px;
	  margin: 10px 0 0 0;
	  width: 505px;
	  display: flex;
	  justify-content: space-between;
	  flex-wrap: wrap;
  }
  .none_Product {
	margin-top: 5rem;
	margin-bottom: 5rem;
  }
 } 
 
 /* iphone (6/7/8/Plus) */
  @media (max-width: 414px) {
	.favorites-inner {
		width: 405px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		margin: 10px 0 0 -23px;
  }
  .favorites {
		margin: 0 auto;
		width: 405px;
  }
  .product_Title {
	font-weight: 500;
	font-size: 25px;
	color: #888282;
}

.none_Product {
	font-weight: 500;
	font-size: 25px;
	color: #888282;
	margin-top: 2.5rem;
	margin-bottom: 2.5rem;
}
 } 
 
 @media (max-width: 395px) {
  .favorites {
		margin: 0 auto 0 auto;
		width: 380px;
  }
  .favorites-inner {
	margin: 1rem 0 0 -39px;
	width: 300px;
	display: flex;
	flex-wrap: wrap;
		justify-content: space-between;
}
.product_Title {
	font-weight: 500;
	font-size: 23px;
	color: #888282;
}

.none_Product {
	margin: 0 0 0 20px;
	font-weight: 500;
	font-size: 23px;
	color: #888282;
	margin-top: 2.5rem;
	margin-bottom: 2.5rem;
}
 } 
 
 /* iphone (6/7/8) */
  @media (max-width: 375px) {
	.favorites {
		margin: 0 auto;
		width: 330px;
  }
  .favorites-inner {
	  width: 320px;
	  margin: 10px 0 0 -37px;
	  display: flex;
	  flex-wrap: wrap;
	  justify-content: space-around;
  }
  .product_Title {
	font-weight: 500;
	font-size: 20px;
	color: #888282;
}

.none_Product {
	font-weight: 500;
	font-size: 20px;
	color: #888282;
	margin-top: 2rem;
	margin-bottom: 2rem;
}
 } 
 
 /* iphone (5/SE) */
  @media (max-width: 320px) {
	 
 } 
 @media (max-width: 280px) {
	 .favorites {
		 width: 150px;
		 margin: 0 auto;
	 }
	 .favorites-inner {
		 width: 150px;
		 margin: 0 auto;
		 display: block;
	 }
} 