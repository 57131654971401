.breadcumps{
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
	 margin-left: 5px;
}
.breadcumps_inner:not(:first-child){
    margin-left: 0.5rem;
}
.breadcumps_inner a{
    font-size: 16px;
    color: black;
    text-decoration: none;
}