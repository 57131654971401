.sidebar {
	min-width: 300px;
	height: 680px;
}

.scrollType {
	overflow-y: scroll;
	height: 90%;
}

.scrollNone {
	overflow: hidden;
}

.catalog {
	height: 670px;
}

.catalog-title {
	align-items: center;
	display: flex;
	background: #ED1C24;
	padding: 0.5rem 0 0.5rem 1.5rem;
	border-radius: 6px 6px 0px 0px;
	color: white;
}

.catalog-title img {
	margin-right: 1.5rem;
}

.category {
	padding: 0.5rem 0 0.5rem 1.5rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.cat-icon {
	object-fit: contain;
	margin-right: 0.5rem;
	width: 22px;
	height: 22px;
}

.cat-title-cont {
	width: 100%;
	display: flex;
	align-items: center;
}

.cat-title {
	margin: 6px;
	cursor: pointer;
	color: #414141;
}

.cat-arrow {
	cursor: pointer;
	margin-left: auto;
	width: 7px;
	height: 13px;
}

.sidebar {
	border-radius: 0px 0px 6px 6px;
	background: #F4F3F8;
}

.slideshow {
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 0 auto;
	overflow: hidden;
	width: 100%;
	max-width: 900px;
	margin-left: 1rem;
}

.slide {
	background-size: cover;
	display: inline-block;
	background-repeat: no-repeat;
	background-position: center;
	height: 450px;
	width: 100%;
	border-radius: 10px;
}

.slideshowSlider {
	transition: ease 1000ms;
	white-space: nowrap;
}

.slideshowDots div:not(:last-child) {
	margin-right: 5px;
}

.slideshowDots {
	position: absolute;
	bottom: 1.5rem;
	left: 3rem;
}

.slideshowDot {
	display: inline-block;
	height: 20px;
	width: 20px;
	height: 2px;
	width: 7px;
	cursor: pointer;
	background: #FFFFFF;
	border-radius: 2px;
}

.active {
	background-color: #ED1C24;
	width: 24px;
}

.slide-title {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 50%;
	background: rgb(0, 0, 0, 0.5);
	height: 100%;
	border-radius: 6px 0px 0px 6px;
}

.slide-title p {
	text-align: right;
	word-break: break-all;
	white-space: normal;
	color: white;
	margin: 0;
	word-wrap: break-word;
}

.hero {
	display: flex;
}

.know-more {
	background: #ED1C24;
	border-radius: 6px;
	padding: 1rem;
	border: none;
	outline: none;
	color: white;
	margin-top: 2rem;
}

.banner {
	width: 290px;
	height: 215px;
	cursor: pointer;
	background-repeat: no-repeat;
	position: relative;
}

.banner-wrap {
	padding: 1rem;
	height: 100%;
	width: 100%;
}

.banner p {
	margin: 0;
}

.discount {
	top: 45%;
	position: absolute;
	left: 4rem;
	color: white;
}

.toolPic {
	max-height: 140px;
	max-width: 140px;
	/* float: right; */
	position: absolute;
	right: 1rem;
	top: 25%;
}

.toolPic-cat {
	max-width: 200px;
	max-height: 160px;
}

.toolpic-cat-cont {
	margin-right: 2rem;
	display: flex;
	justify-content: flex-end;
}

.banners {
	margin-top: auto;
	display: flex;
	flex-direction: row;
}

.banner:not(:last-child) {
	margin-right: 20px;
}

.advantages {
	display: flex;
	flex-direction: row;
	margin-top: 40px;
}

.advantage {
	width: 290px;
	border: 1px solid #D2CEE0;
	box-sizing: border-box;
	border-radius: 6px;
}

.advantage-wrap {
	padding: 16px;
}

.white-circle-cont {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.adv-first-row img:nth-child(2) {
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
}

/* .adv-first-row img{
  padding: 1rem;
  border-radius: 50%;
  border: 1px #f4f3f8 solid;
  background-color: #F4F3F8;
} */
.adv-first-row p {
	margin: 0;
	color: #ED1C24;
}

.adv-first-row {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.advantage-title {
	margin-top: 1rem;
	font-size: 22px;
	color: #414141;
}

.advantage:not(:last-child) {
	margin-right: 20px;
}

.about-us {
	height: 500px;
	margin-top: 40px;
	background: #F4F3F8;
	display: flex;
	flex-direction: row;
}

.left-half {
	position: relative;
	width: 50%;
}

.left-half,
.left-half-wrap {
	height: 100%;
}

.left-half-wrap {
	padding: 50px 10px 0 50px;
}

.left-desc {
	padding-left: 40px;
	font-weight: 400;
	font-style: normal;
	font-size: 16px;
	color: #414141;
}

.red-about p {
	font-size: 22px;
}

.red-about {
	color: white;
	background: red;
	padding: 0px 13px 22px 40px;
	width: 300px;
	font-style: normal;
	/* float: right; */
	margin: 0;
}

.about-us-mid {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex-direction: column;
}

.red-about-description {
	font-weight: normal;
	padding: 30px;
	width: 200px;
	background: red;
	height: 285px;
	color: white;
}

.about-us-pic {
	height: 100%;
	width: 400px;
	background-repeat: no-repeat;
}

.card {
	-webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
	-moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
	box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
	/* margin-right: 2rem; */
	width: 250px;
	border: 1px solid #D2CEE0;
	box-sizing: border-box;
	border-radius: 6px;
	/* margin-right: 20px; */
}

.card-wrapper p {
	color: black;
}

.btns {
	display: flex;
}

.compare {
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 35px;
	margin-right: 5px;
}

.cart-setnum {
	margin-top: auto;
}

.card-wrapper {
	height: 100%;
	position: relative;
	padding: 0 24px 0 24px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.item-pic-container {
	margin-top: 1rem;
	width: 200px;
	height: 200px;
}

.item-pic {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.rating {
	margin-top: 30px;
}

.popular-wrapper {
	justify-content: space-around;
	display: flex;
	flex-direction: row;
	margin-bottom: 2.5rem;
}

.new-modal {
	background: #6FAC54;
	;
	border-radius: 4px;
	/* position: absolute; */
	color: white;
	font-size: 16px;
	padding: 2px 12px;
}

.cart {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.left-quality {
	height: 100%;
	width: 50%;
	position: relative;
}

.quality {
	display: flex;
	flex-direction: row;
	height: 360px;
}

.left-q-text {
	color: white;
	padding: 70px 40px;
}

.left-q-title {
	font-size: 32px;
}

.left-q-desc {
	margin-top: 30px;
	padding-left: 40px;
}

.quality-image {
	width: 70%;
	bottom: -57px;
	position: absolute;
	right: 0;
}

.right-quality {
	padding: 0 60px 0 60px;
	justify-content: center;
	height: 100%;
	width: 50%;
	background-color: #F4F3F8;
	display: flex;
}

.mini-adv {
	display: flex;
	align-items: flex-start;
	flex-direction: row;

}

.white-circle {
	margin: 1rem 1rem 0 0;
	display: flex;
	/* padding: 1rem; */
	min-width: 45px;
	border-radius: 50%;
	background: white;
	min-height: 45px;
	align-items: center;
	justify-content: center;
	position: relative;
}

.mini-adv-pic {
	position: absolute;
}

.mini-adv0 {
	grid-area: mini-adv0;
}

.mini-adv1 {
	grid-area: mini-adv1;
}

.mini-adv2 {
	grid-area: mini-adv2;
}

.mini-adv3 {
	grid-area: mini-adv3;
}

.right-quality-wrap {
	align-items: center;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 0px 0px;
	grid-template-areas:
		"mini-adv0 mini-adv2"
		"mini-adv1 mini-adv3";
}

.new-items-wrapper {
	justify-content: space-around;
	display: flex;
	flex-direction: row;
}

.subcat {
	text-align: left;
	cursor: pointer;
	margin-top: 1rem;
	padding: 0 1rem;
}

.subcatalog {
	text-align: center;
	background: #F4F3F8;
	border-radius: 0px 6px 6px 0px;
	height: 555px;
	width: 250px;
	position: absolute;
	top: 4.3rem;
	z-index: 1;
}

.active {
	color: #ED1C24;
}

.modals {
	position: absolute;
	width: 100%;
	display: flex;
}

/* For Media (MOBILE) */

/* Ipad(Pro) */
@media(max-width:1050px) {
	.banners {
		flex-wrap: wrap;
		justify-content: center;
	}

	.catalog {
		height: 600px;
	}

	.banner:not(:first-child) {
		margin-top: 1rem;
	}

	.slide-title {
		width: 100%;
	}

	.left-desc p {

		font-size: 12px;
	}

	.left-half-wrap {
		padding: 0px;
	}

	.left-half {
		width: 40%;
	}

	.about-us-mid {
		margin-left: 5rem;
	}

	.red-about {
		width: 260px;
	}

	.red-about-description {
		width: 160px;
	}

	.new-items-wrapper {
		flex-wrap: wrap;
		justify-content: space-around;
	}

	.popular-wrapper {
		flex-wrap: wrap;
		justify-content: space-around;
	}

	.quality-image {
		width: 50%;
		bottom: -30px;
	}

	.search-input {
		width: 160px !important;
	}

	.nav {
		display: none;
	}
}

@media(max-width:820px) {
	.sidebar {
		height: 100%;
	}

	.left-q-text {
		padding: 0px 10px;
	}

	.catalog {
		height: 620px;
	}

	.slide {
		background-size: 295% 100%;
	}

	.about-us-mid {
		margin: 0;
	}

	.red-about-description img {
		padding: 0;
		margin-left: -15px;
	}

	.red-about {
		margin-bottom: -30px;
	}

	.mini-adv {
		margin: 0 !important;
		text-align: center;
		align-items: center;
		padding: 0;
		flex-direction: column;
	}

	.footer-wrap {
		flex-direction: column !important;
	}

	footer {
		height: 100% !important;
	}

	.slideshow {
		margin-left: 0px;
		margin-top: 1rem
	}

	.subcatalog-mob {
		padding: 1rem 0 0 1rem;
	}

	.category {
		flex-direction: column;
		align-items: flex-start;
	}

	.hero {
		flex-direction: column;
	}

	.about-us {
		justify-content: space-between;
	}

	.right-quality-wrap {
		padding: 2rem;
	}

	.right-quality {
		width: 100%;
	}

	.quality {
		height: 100%;
	}

	.card {
		margin-bottom: 1rem;
	}

	.advantages {
		flex-wrap: wrap;
		justify-content: center;
	}

	.advantage {
		margin-right: 20px;
		margin-top: 20px;
	}

	.left-half {
		width: 100%;
	}

	.about-us-pic {
		display: none;
	}

	.quality {
		flex-direction: column;
		align-items: center;
	}

	.left-quality {
		width: 100%;
	}

	.left-q-desc {
		font-weight: 400;
		margin: 0 0 0 -37px;
		padding-bottom: 5px;
	}

	.quality-image {
		position: absolute;
		bottom: 12px;
	}
}

/* Surface Duo() */
@media (max-width: 540px) {
	.sidebar {
		height: 100%;
	}

	.card {
		width: 200px;
	}

	.cart {
		display: block;
		width: 100%;
		align-items: center;
		justify-content: space-between;
	}

	.btns {
		margin-left: 50px;
	}

	.left-q-desc {
		font-weight: 400;
		margin: 0 0 0 -37px;
		padding-bottom: 5px;
	}

	.quality-image {
		position: absolute;
		bottom: 75px;
		right: 1px;
	}
}

/* iphone (6/7/8/Plus) */
@media (max-width: 414px) {

	.sidebar {
		height: 100%;
	}

	.btns {
		margin-left: 50px;
	}

	.white-circle {
		margin-top: 30px;
	}

	.mini-adv {
		height: 200px;
	}

	.left-desc {
		margin-left: -25px;
	}

	.left-half-wrap {
		height: 50%;
	}

	.left-half {
		height: 250px;
	}

	.red-about {
		margin: 0 auto;
	}

	.red-about-description {
		margin: 0 auto;
	}

	.about-us {
		display: block;
		justify-content: space-around;
		margin-bottom: 380px;
	}

	.popular-wrapper {
		margin: 0 1.5rem 0 -15px;
	}

	.new-items-wrapper {
		margin: 0 1.5rem 0 -15px;
	}

	.left-q-desc {
		font-weight: 400;
		margin: 0 0 0 -37px;
		padding-bottom: 5px;
	}

	.quality-image {
		position: absolute;
		right: 15px;
		top: 150px;
	}
}

@media (max-width: 395px) {
	.slide-title p {
		font-size: 15px;
	}

	.btns {
		margin-left: 42px;
	}

	.about-us {
		display: block;
		justify-content: space-around;
		margin-bottom: 400px;
	}

	.red-about {
		margin-top: 25px;
	}

	.new-items-wrapper {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		width: 340px;
		margin: 0 2.8rem 0 1.6rem;
	}

	.popular-wrapper {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		width: 340px;
		margin: 0 2.8rem 0 1.6rem;
	}

	.left-q-desc {
		font-weight: 400;
		margin: 0 0 0 -37px;
		padding-bottom: 5px;
	}

	.quality-image {
		position: absolute;
		right: 5px;
		top: 52px;
	}
}

/* iphone (6/7/8) */
@media (max-width: 375px) {
	.quality-image {
		position: absolute;
		right: 5px;
		top: 55px;
	}

	.btns {
		margin-left: 25px;
	}
}

@media (max-width: 320px) {
	.mini-adv {
		padding: 1px 3px;
		height: 200px;
	}

	.quality-image {
		position: absolute;
		right: 5px;
		top: 1px;
	}

	.left-desc {
		margin-top: -10px;
	}

	.popular-wrapper {
		display: block;
		width: 130px;
		margin: 0 auto;
		flex-wrap: wrap;
	}

	.new-items-wrapper {
		display: block;
		width: 130px;
		margin: 0 auto;
		flex-wrap: wrap;
	}
}

/* iphone (5/SE) */
@media (max-width: 280px) {
	.quality-image {
		position: absolute;
		right: 5px;
		top: 1px;
	}
}